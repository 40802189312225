/* global $ */

import './mobilemenu.scss';

import * as util from '../shared/js/utilities';

export function initialize() {
  // measure submenu blocks after a little timeout
  // window.setTimeout(() => {
  //   $('.mobilemenu__navsublist').each(function(idx, el) {
  //     $(this).css('height', $(this).height()).addClass('closed');
  //     $('.mobilemenu').addClass('initialized');
  //   });
  // }, 1000);

  // setup toggle mobile menu click event
  $('.js-toggle-mobile-menu').on('click', function(evt) {
    evt.preventDefault();

    const openingMenu = !$('html').hasClass('mobile-menu-open');
    $('html').toggleClass('mobile-menu-open');

    if (openingMenu) {
      $('html').removeClass('mobile-search-open');
      $('html').removeClass('mobile-news-open');
      $('body').css({ position: 'fixed', top: `-${util.getScrollY()}px`, width: '100vw' });
    } else {
      closeMenu();
    }
  });//.trigger('click');

  $('.mobilemenu').addClass('initialized');
}

export function closeMenu() {
  const scrollY = parseInt($('body').css('top') || 0);

  if (scrollY) {
    $('body').css({ position: '', top: 0});
    window.scrollTo(0, -1 * parseInt(scrollY || '0'));
  } else if (scrollY === 0) {
    $('body').css({ position: '', top: 0});
  }

  $('html').removeClass('mobile-menu-open');
}
