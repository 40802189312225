/* global $ */

import './newsletter.scss';

import * as dompack from 'dompack';
import { RPCFormBase } from '@mod-publisher/js/forms';

import * as util from '../../shared/js/utilities';

export default class NewsletterForm extends RPCFormBase {
  constructor(node){ // node = .newsletterform
    super(node);
    this.node = node;

    if (dompack.debugflags["debug"])
      node.email.value = 'wouter@webwerf.nl';
  }

  async onRPC(promise) {
    $(this.node).find('.wh-form__button--submit').addClass('button--loading');
    try {
      await promise;
    } catch (e) {
      util.triggerGeneralErrorDialog(e);
    } finally {
      $(this.node).find('.wh-form__button--submit').removeClass('button--loading');
    }
  }

  onSubmitFailed(errors, result) {
    util.triggerGeneralErrorDialog();
  }
}
