/* global $ */

import './w-quotes.scss';

import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade]);

export default class {
  // node = .w-quotes
  constructor(node) {
    const $container = $(node);
    const $swiper = $container.find('.swiper-container').get(0);

    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides < 1)
      return;

    if (numSlides == 1)
      $(node).addClass('w-quotes--single-slide');

    const swiper = new Swiper($swiper, {
      loop: true,
      spaceBetween: 0,
      slidesPerView: 'auto',
      grabCursor: true,
      a11y: {
        prevSlideMessage: 'Vorige',
        nextSlideMessage: 'Volgende',
      },
    });

    $(node).find('.js-quotes-swiper-nav-prev').click((evt) => {
      evt.preventDefault();
      swiper.slidePrev();
    });

    $(node).find('.js-quotes-swiper-nav-next').click((evt) => {
      evt.preventDefault();
      swiper.slideNext();
    });
  }
}
