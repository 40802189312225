import createRPCClient from "@webhare/jsonrpc-client";

interface AtGroepRPC {
  getVacancies(fileid: number): Promise<string>;
}

export default class {
  node: HTMLElement;

  constructor(node: HTMLElement) {
    this.node = node;
    this.setVacancies();
  }

  async setVacancies() {
    const elHtmlContainer = this.node.querySelector('.js-widget-vacancies-html');
    if (!elHtmlContainer) {
      return;
    }
    const client = createRPCClient<AtGroepRPC>("at_groep:rpc");
    const fileId = Number(this.node.dataset.fileid);
    elHtmlContainer.innerHTML = await client.getVacancies(fileId);
    window.setupButtons();
  }
}
