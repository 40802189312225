/* global $ */

import "./scrolltotop.scss";

import * as dompack from 'dompack';

class ScrollToTop {
  constructor() {
    $('.js-scrolltotop').on('click', (evt) => {
      evt.preventDefault();
      dompack.qS('html').scrollIntoView({block:'start',behavior:'smooth'});
    });
  }
}

dompack.register('.scrolltotop', node => new ScrollToTop(node));
