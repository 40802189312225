/* global $ */

import './slideshow.scss';
import * as dompack from 'dompack';

import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade]);

export default class Slideshow {
  // node = .slideshow
  constructor(node) {
    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides < 1)
      return;

    if (numSlides == 1)
      $(node).addClass('slideshow--single-slide');

    const allowSwipe = numSlides > 1;//$(window).width() < 700;
    // const fadeTransition = $(window).width() >= 700;

    let settings = {
      direction: 'horizontal',
      loop: true,
      speed: 900,
      // noSwiping: true,
      allowSlidePrev: allowSwipe,
      allowSlideNext: allowSwipe,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      effect: 'fade',//fadeTransition ? 'fade' : 'slide',
    };

    const swiper = new Swiper(node.querySelector('.swiper-container'), settings);

    $(node).find('.js-slideshow-swiper-nav-prev').click((evt) => {
      evt.preventDefault();
      swiper.slidePrev();
    });

    $(node).find('.js-slideshow-swiper-nav-next').click((evt) => {
      evt.preventDefault();
      swiper.slideNext();
    });

    window.addEventListener('resize', onResize);
    onResize();
  }
}

function onResize() {
  const degrees = 8.23;

  const $prevButton = $('.swiper-button-prev');
  const leftPrevButton = parseInt($prevButton.css('left'));
  const topPrevButton = leftPrevButton * getTanFromDegrees(degrees);
  $prevButton.css('bottom', topPrevButton);

  const $nextButton = $('.swiper-button-next');
  const leftNextButton = parseInt($nextButton.css('left'));
  const topNextButton = leftNextButton * getTanFromDegrees(degrees);
  $nextButton.css('bottom', topNextButton);
}

function getTanFromDegrees(degrees) {
  return Math.tan(degrees * Math.PI / 180);
}

dompack.register(".slideshow", node => new Slideshow(node));
