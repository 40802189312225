/* global $ */

import './modal.scss';

import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';

export async function runModal(contents, options) {
  options = { allowcancel: true,
              beforeOpen: null,
              clone: false,
              borrow: '',
              ...options,
            };

  // create dialog
  let dialog = dialogapi.createDialog(options);

  // add contents to it
  if (typeof(contents) == 'object') // assuming a DOM object
    dialog.contentnode.appendChild(contents);
  else if (typeof(contents) == 'string') // assuming HTML
    $(dialog.contentnode).append($(contents));

  if (options.allowcancel) {
    addButton(dialog);
  }

  if (options.beforeOpen) {
    options.beforeOpen(dialog);
  }

  // show dialog
  await dialog.runModal();

  // after closing, reset the form
  const $formContainer = $(dialog.options.borrow);
  const $form = $formContainer.find('form.wh-form');
  $form.addClass('wh-form--allowsubmit');
  $form.find('.wh-form__page').eq(0).removeClass('wh-form__page--hidden').addClass('wh-form__page--visible');
  $form.find('.wh-form__page').eq(1).removeClass('wh-form__page--visible').addClass('wh-form__page--hidden');
}

export class ATGDialog extends dialog.BasicDialog {
  constructor(options) {
    super('mydialog',options);
    addButton(this);
    $(this.contentnode).find('.dompack-messagebox__button').addClass('button');
  }

  afterShow() {
    $(this.contentnode).find('.dompack-messagebox__button').addClass('button button--secondary');
  }
}

function addButton(dialog) {
  let button = dompack.create('button', { className: 'modal__closebutton'
                                        , textContent: ''
                                        , type: 'button'
                                        , on: { click: event => { dompack.stop(event); dialog.resolve(); } }
                                        });

  $(dialog.contentnode).closest('.mydialog__holder').append($(button));

  const $closeButton = $(`<a href="#" class="roundnavbutton"><span class="roundnavbutton__icon"></span></a>`);
  $closeButton.find('.roundnavbutton__icon').append($('#svgicon-close').html());
  $(button).append($closeButton);
}

dialogapi.setupDialogs(options => new ATGDialog(options));
