/* global $ */

import './w-services.scss';

export default class {
  // node = .w-services
  constructor(node) {
    const $container = $(node);
    const $links = $container.find('.ctatextlinks__link');
    const $images = $container.find('.js-hover-itemimage');
    const $items = $container.find('.js-hover-item');

    $(window).resize(function() {
      // get the longest title & summary
      const maxTitle = Math.max.apply(null, $container.find('.js-hover-item').map(function () {
        return $(this).get(0).getBoundingClientRect().height;
      }).get());

      $('.js-hover-item-container').css('min-height', maxTitle);
    }).trigger('resize');

    // when hovering an item, show the item details
    $links.on('mouseenter', function() {
      const $linkContainer = $(this).closest('.ctatextlinks');
      const id = parseInt($linkContainer.data('id'));
      const $image = $container.find(`.js-hover-itemimage[data-id="${id}"]`);

      $images.removeClass('active'); // set all images inactive
      if ($image)
        $image.addClass('active'); // and enable the correct one

      $items.removeClass('active'); // set alle items inactive
      const $item = $container.find(`.js-hover-item[data-id="${id}"]`);
      if ($item)
        $item.addClass('active'); // and enable the correct one
    });

    // when leaving the (full) items container, remove the details
    $links.on('mouseleave', function() {
      $(' items__hovercontainer').fadeOut(300);
    });
  }
}
