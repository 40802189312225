/* global $ */

import './overview.scss';

import * as whintegration from '@mod-system/js/wh/integration';
import * as dompack from 'dompack';
import Sticky from 'sticky-js';
import Shuffle from 'shufflejs';

let debug = !whintegration.config.islive;

function isFilterEnabled(eFilter) {
  if (eFilter.tagName === 'INPUT') {
    return eFilter.checked === true;
  }

  if (eFilter.tagName === 'SELECT') {
    const value = eFilter.options[eFilter.selectedIndex].value;
    return !!value;
  }

  return false;
}

export default class {
  // node = .p-overview
  constructor(node) {
    setupStickySideBar();

    if (whintegration.config.obj.gridcss === true) {
      return; // using (grid) CSS instead of shuffle, so we're done here
    }

    // get 'highest' card
    if ($(node).find('.card').length > 3) {
      const maxHeight = Math.max.apply(null, $(node).find('.card').map(function () {
        return $(this).get(0).getBoundingClientRect().height;
      }).get());

      $(node).find('.card').css('height', maxHeight);
    }

    var Demo = function(element) {
      const eOverview = element.closest('.p-overview');
      this.filterInputs = Array.from(eOverview.querySelectorAll('.c-filters input, .c-filters select'));

      this.shuffle = new Shuffle(element, {
        easing: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)', // easeOutQuart
        sizer: '.the-sizer',
      });

      // setup filters object
      this.filters = {
        // filter1: [],
        // filter2: [],
      };

      const eFiltersContainer = eOverview.querySelector('.js-filters');
      const dataFilters = eFiltersContainer && eFiltersContainer.dataset && eFiltersContainer.dataset.filters ? JSON.parse(eFiltersContainer.dataset.filters) : [];
      if (dataFilters.length)
        whintegration.config.obj['overviewpage_filters'] = dataFilters;

      log('whfilters', whintegration.config.obj.overviewpage_filters);

      if (whintegration.config.obj.overviewpage_filters) {
        for (const filter of whintegration.config.obj.overviewpage_filters) {
          this.filters[filter.name] = [];
        }
      }

      log('filters', this.filters);

      this.$searchInput = whintegration.config.obj.textsearch ? $('.js-overview-search-text') : [];

      this._bindEventListeners();

      // $('select').get(0).value = 'GZ psycholoog';
      // this.filter();
    };

    Demo.prototype._bindEventListeners = function() {
      for (const input of this.filterInputs) {
        input.addEventListener('change', this._handleChange.bind(this));
      }

      if (this.$searchInput.length) {
        this.$searchInput.on('input', this._handleChange.bind(this));
      }
    };

    // Set current filters based on checked inputs
    Demo.prototype.setFilters = function() {
      for (const filter of whintegration.config.obj.overviewpage_filters) {
        // reset the filter
        this.filters[filter.name] = [];

        // gather inputs of this filter
        const inputs = dompack.qSA(`.js-filter-category[data-name="${filter.name}"] input`);
        log('inputs', inputs);

        // add the checked filters
        for (const input of inputs) {
          if (input.checked) {

            log(`Added filter for "${filter.name}" with value "${input.value}"`);
            this.filters[filter.name].push(parseInt(input.value) || input.value);
          }
        }

        // same for selects
        const elSelects = dompack.qSA(`.js-filter-category[data-name="${filter.name}"] select`);
        log('selects', elSelects);

        // add selects with a value
        for (const elSelect of elSelects) {
          const selectValue = elSelect.options[elSelect.selectedIndex].value;
          if (selectValue) {
            log(`Added filter for "${filter.name}" with value "${selectValue}"`);
            this.filters[filter.name].push(parseInt(selectValue) || selectValue);
          }
        }
      }
    };

    // Change event
    Demo.prototype._handleChange = function(evt) {
      this.filter();
    };

    // Filter the items
    Demo.prototype.filter = function() {
      console.clear();
      log('hasActiveFilters', this.hasActiveFilters());
      if (this.hasActiveFilters()) {
        this.setFilters();
        this.gridItemIdx = -1;
        this.shuffle.filter(this.itemPassesFilters.bind(this));
      } else {
        this.shuffle.filter(Shuffle.ALL_ITEMS);
      }
    };

    // hasActiveFilters if there's at least one <input> checked or a <select> with a value
    Demo.prototype.hasActiveFilters = function() {
      return   (this.$searchInput.length > 0 && this.$searchInput.val() !== '') // has text search field and filled in
            || (this.filterInputs.some(eFilterInput => isFilterEnabled(eFilterInput))); // one or more filters checked/selected
    };

    // Determine whether an element passes the current filters.
    Demo.prototype.itemPassesFilters = function(element) {
      this.gridItemIdx++;

      if (!element.classList.contains('js-grid-item')) {
        return false;
      }


      for (const filter of whintegration.config.obj.overviewpage_filters) {
        const lookingForIds = this.filters[filter.name];
        if (lookingForIds.length == 0) {
          log('skip', filter.name);
          continue; // not filtering on this one, so skip
        }

        log(`------------ Filtering grid item #${this.gridItemIdx}, "${filter.name}", for values: `, lookingForIds, '------------');

        const itemIds = JSON.parse(element.getAttribute(`data-${filter.name}`));
        log(`Value for field "${filter.name}": `, itemIds);

        const foundItems = lookingForIds.filter(x => itemIds.includes(x));
        if (foundItems.length == 0) { // we're filtering but not found? return false, we're done
          log(`❌ Eliminating grid item #${this.gridItemIdx} because the value is: `, itemIds);
          return false;
        }

        log(`✅ Adding grid item #${this.gridItemIdx} because the value is: `, itemIds);
      }

      // optionally search by text
      if (this.$searchInput.length && this.$searchInput.val()) {
        log(this.$searchInput.val());
        const includesSearchText = element.getAttribute('data-searchtext').toUpperCase().includes(this.$searchInput.val().toUpperCase());
        if (!includesSearchText) {
          return false;
        }
      }

      return true;
    };

    if ($('.js-shuffle').length > 0)
      new Demo(document.querySelector('.js-shuffle'));
  }
}

function log(...args) {
  if (debug)
    console.log(...args);
}

function setupStickySideBar() {
  if ($(window).width() > 500)
    new Sticky('.sticky');
}
