/* global $ */

import './home.scss';

export default class HomeScrollDown {
  // node = .js-home-scrolldown
  constructor(node) {
    $(node).on('click', function(evt) {
      evt.preventDefault();
      $('html,body').animate({ scrollTop: $(this).closest('.p-home__scrolldown').next().offset().top }, 700);
    });
  }
}
