/* global $ */

import * as dompack from 'dompack';

class HeaderMenu {
  constructor() {
    $('.headermenu > ul > li > a').each(function() {
      $(this).focusin(function() {
        $('li.hassubitems').removeClass('focus');
        $(this).closest('li.hassubitems').addClass('focus');
      });
    });

    $('li.hassubitems > ul > li > a').each(function() {
      $(this).focusin(function(evt) {
        $('li.hassubitems').removeClass('focus');
        $(this).closest('li.hassubitems').addClass('focus');
      });

      $(this).focusout(function(evt) {
        if (evt.relatedTarget.classList.contains('hassubitems')) {
          $(this).closest('li.hassubitems').removeClass('focus');
        }
      });
    });
  }
}

dompack.register('.headermenu', node => new HeaderMenu(node));
