/* global $ */

import './vacancies.scss';

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

dompack.onDomReady(() => {
  setupVacancyDetails();
});

function setupVacancyDetails() {
  if (!document.documentElement.classList.contains('page-vacancy-details'))
    return;

  $('footer.footer').css('opacity', '1').removeClass('aos-init').get(0).removeAttribute('data-aos');
  $('input[name="vacancy_name"]').val(whintegration.config.obj.vacancyname);
  $('input[name="vacancy_url"]').val(whintegration.config.obj.vacancyurl);
}
