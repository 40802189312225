/* global $ */
import './servicecards.scss';

export default class {
  // node = .w-servicecards
  constructor(node) {
    window.addEventListener('resize', onResize);
    onResize(node);
  }
}

function onResize(node) {
  $(node).each(function() {
    // get 'highest' card
    let maxHeight = Math.max.apply(null, $(this).find('.swiper-slide').map(function () {
      return $(this).get(0).getBoundingClientRect().height;
    }).get());

    maxHeight = Math.max(611, maxHeight);

    // set wrapper height
    $(this).find('.swiper-wrapper').css('min-height', maxHeight);
    $(this).closest('.widget--servicecards').css('minHeight', maxHeight);
  });

  $('.w-servicecards .card').on('mouseenter', function() {
    $(this).closest('.widget--servicecards').addClass('hover');
  });

  $('.w-servicecards .card').on('mouseleave', function() {
    $(this).closest('.widget--servicecards').removeClass('hover');
  });
}
