/* global $ */

import './sidebarcontact.scss';

import * as util from '../../shared/js/utilities';

export default class SideBarContact {
  constructor(node) { // node is .sidebarcontact
    for (const type of ['phone','address','email']) {
      $(`.sidebarcontact__mainicon--${type}`).on('mouseenter', function() {
        $(`.sidebarcontact__${type}`).addClass('show');
      });

      $(`.sidebarcontact__${type}`).on('mouseleave', function() {
        $(this).removeClass('show');
      });
    }

    $('.sidebarcontact__container').addClass('initialized');

    if ($('html').width() <= 576) {
      window.addEventListener('scroll', ev => this.onScroll(), util.supportsPassive() ? { passive: true } : false);
      this.onScroll();
    }

    $('.js-close-sidebar').click(evt => {
      evt.preventDefault();
      $('.sidebarcontact__container').removeClass('show');
    });
  }

  onScroll() {
    $('html').toggleClass('hidesidebarcontact', util.getScrollY() > 400);
  }
}
