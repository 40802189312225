/* global $ */
import './cards.scss';

import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, EffectFade]);

export default class Cards {
  // node = .cards
  constructor(node) {
    const $container = $(node);
    const $swiper = $container.find('.swiper-container:not(.disabled)').get(0);

    const breakpointDisableSwiper = window.matchMedia('(min-width:992px)'); // Bootstrap's lg

    let swiper;

    let enableSwiper = function()
    {
      swiper = new Swiper($swiper,
      {
        direction: 'horizontal',
        loop: false,
        speed: 300,
        spaceBetween: 20,
        slidesPerView: 'auto',
        grabCursor: true,
        // observer: true,
        scrollbar:
        {
          el: '.swiper-scrollbar',
          draggable: true,
        },
        a11y:
        {
          prevSlideMessage: 'Vorige',
          nextSlideMessage: 'Volgende',
        },
      });
    };

    // disable Swiper from Bootstrap's lg breakpoint, (re-)enable if smaller
    let breakpointChecker = () =>
    {
      if (breakpointDisableSwiper.matches === true )
      {
        $container.addClass('cards--swiper-disabled');

        if (swiper !== undefined)
          swiper.destroy(true, true);

        return;
      }

      $container.removeClass('cards--swiper-disabled');

      return enableSwiper();
    };

    breakpointDisableSwiper.addListener(breakpointChecker);
    breakpointChecker();
  }
}
