/* global $ */
import * as dialogapi from 'dompack/api/dialog';

export async function triggerGeneralErrorDialog(err) {
  if (err)
    console.error(err);

  await dialogapi.runMessageBox('Er heeft zich een onbekende fout voorgedaan.\n\nProbeer het nog eens of neem contact met ons op voor verdere ondersteuning.', [], {});
}

export function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

export function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export function supportsPassive() {
  // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
  // Test via a getter in the options object to see if the passive property is accessed
  let supportsPassive = false;

  try {
    let opts = Object.defineProperty({}, 'passive', {
      get: function() {
        supportsPassive = true;
      }
    });
    window.addEventListener("test", null, opts);
  } catch (e) {}


  return supportsPassive;
}
