/* global $ */

import "./search.scss";

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-search'))
    return;

  $('.js-search-submit').click(function(evt) {
    evt.preventDefault();
    $(this).closest('form').get(0).submit();
  });
});


